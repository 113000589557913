<template>
    <div>
        Home
    </div>
</template>

<script>
import RedirectsAuthenticated from '@/components/auth/mixins/RedirectsAuthenticated';

export default {
    name: 'Index',
    mixins: [RedirectsAuthenticated],
    created() {
        this.redirectAuthenticated();
    },
};
</script>
